{
  "result": "Result",
  "combined_skills": "Together, we combined our skills in business and technology to create summie",
  "dream": "- the app that saves both time and money. We dream of making financial knowledge accessible to everyone.",
  "upgrade_message": "Upgrade to the new way of managing expenses",
  "Save time and money": "Save time and money",
  "with summie.": "with summie",
  "We simplify": "We simplify your finances by keeping everything digital and organized, saving you time and money.",
  "Download": "Download",
  "Our motto": "Our motto",
  "Why summie": "Why summie",
  "In today’s fast-paced world": "In today’s fast-paced world, managing finances shouldn’t slow you down.",
  "We are here to": "We are here to simplify your financial admin, helping you save time and money with an intuitive, digital approach.",
  "Save time": "Save time",
  "Save money": "Save money",
  "No more paper": "No more paper clutter or manual tracking. summie keeps everything digital, organized, and is designed to be intuitive.",
  "Manage your financial": "Manage your financial admin efficiently. summie offers its services at a competitive price, saving you money wherever you go.",
  "Features": "Features",
  "The app that works for you": "The app that works for you",
  "With summie": "With summie, all your receipts and invoices are in one place. Effortlessly add and attach your receipt directly to your transaction. summie makes it simple for every entrepreneur, freelancer, or individual who wants to streamline their financial administration.",
  "Receipt capture": "Receipt capture & storage",
  "all in one secure place": "all in one secure place",
  "Organize": "Organize & track",
  "easy expense management": "easy expense management",
  "Intuitive design": "Intuitive design",
  "and simple to navigate": "and simple to navigate",
  "Export your files": "Export your files",
  "for easy sharing": "for easy sharing",
  "Data security focus": "Data security focus",
  "your privacy, fully protected": "your privacy, fully protected",
  "summie started with a simple idea": "summie started with a simple idea: what if we could make managing receipts easy? As entrepreneurs, we were tired ",
  "of the paper clutter": "of the paper clutter and existing inefficient tools. We knew we needed something better, not just for ourselves but for every professional or individual who values their time.",
  "summie B.V. All rights reserved.": "summie B.V. All rights reserved.",
  "Follow us": "Follow us",
  "on Instagram for updates": "on Instagram for updates",
  "Privacy Policy": "Privacy Policy",
  "Terms and Conditions": "Terms and Conditions",
  "Blogs": "Blogs",
  "overview-mobile": "/img/mobile/overview.png",
  "overview-desktop": "/img/desktop/overview.png",
  "library-mobile": "/img/mobile/library.png",
  "library-desktop": "/img/desktop/library.png",
  "widget-mobile": "/img/mobile/widget.png",
  "widget-desktop": "/img/desktop/widget.png",
  "Privacy-policy": "Privacy policy",
  "privacy": {
    "lastUpdated": "Last updated: January, 2025",
    "introduction": "summie B.V. (hereinafter referred to as 'summie', 'we', 'our') respects your privacy and ensures that your personal data is processed securely. This privacy policy explains how we collect, use, store, and protect your personal data when you use our services, both via our website summie.co and our app. This policy is drafted in accordance with the General Data Protection Regulation (GDPR) and other relevant legislation.",
    "whatDataTitle": "What data do we collect?",
    "whatDataDescription": "We collect various types of personal data from users who use our website and/or app:",
    "data": {
      "registration": {
        "title": "Registration and authentication data:",
        "name": "Name",
        "email": "Email address",
        "userID": "Unique user ID",
        "password": "Encrypted password (for manual registration)"
      },
      "payment": {
        "title": "Payment information:",
        "details": "Bank account details, such as IBAN and transaction information, collected via our payment provider GoCardless."
      },
      "technical": {
        "title": "Technical data:",
        "details": "Information about your use of our app, such as device information, location (if allowed), and other log data collected through cookies and similar technologies."
      },
      "analytics": {
        "title": "Data analytics:",
        "details": "Anonymized user interactions with the app, including scrolls and clicks."
      },
      "advertising": {
        "title": "Advertising and conversion tracking data:",
        "details": "Data collected via the Facebook SDK, like conversion events."
      }
    },
    "whyDataTitle": "Why do we collect this data?",
    "whyDataDescription": "We use your data for the following purposes:",
    "whyData": {
      "createAccount": "To create a user account and provide access to our services.",
      "processSubscriptions": "To process subscriptions via RevenueCat.",
      "transactions": "To handle transactions and bank account data via GoCardless.",
      "userExperience": "To improve the user experience by analyzing usage patterns and preferences.",
      "advertisingEffects": "To track and measure the effectiveness of advertising campaigns on Instagram.",
      "legalObligations": "To comply with legal obligations and ensure the security of our systems."
    },
    "howDataTitle": "How do we collect data?",
    "howDataDescription": "We collect data in various ways, depending on your interaction with our services:",
    "howData": {
      "createAccount": "When you create an account or log in via Apple or Google.",
      "connectBank": "When you connect your bank via GoCardless.",
      "technicalData": "Automatically collecting technical data via Google Firebase about your use of the app.",
      "analytics": "Anonymized user interactions about app usage with Microsoft Clarity.",
      "advertising": "We collect and use the Identifier for Advertisers (IDFA) from your device to track and measure advertising effectiveness for campaigns conducted on platforms such as Instagram. This data is shared with Facebook for attribution and conversion tracking purposes."
    },
    "shareDataTitle": "Do we share your data with third parties?",
    "shareDataDescription": "Yes, we share your personal data with third parties, but only when necessary to provide our services:",
    "shareData": {
      "gocardless": "GoCardless: For processing transactions and bank details.",
      "firebase": "Google Firebase: For hosting, storage, and database management of your data.",
      "appleGoogle": "Apple and Google (OAuth): For authentication via 'Sign in with Apple' and 'Sign in with Google'.",
      "revenuecat": "Apple and RevenueCat: For subscription and payments.",
      "microsoftClarity": "Microsoft Clarity: For app usage and investigation of issues.",
      "facebook": "Facebook: For tracking advertising effectiveness and measuring conversions on platforms like Instagram.",
      "devTeams": "External development teams: For maintenance and app improvements."
    },
    "yourRightsTitle": "Your rights",
    "yourRightsDescription": "As a user of our services, you have several rights regarding your personal data:",
    "yourRights": {
      "access": "You have the right to access the data we process about you.",
      "correct": "You can request corrections to inaccurate or incomplete data.",
      "delete": "You can request the deletion of your personal data (unless we are legally required to retain it).",
      "restrict": "You can request a temporary restriction on the processing of your data.",
      "withdraw": "You can withdraw your consent for data processing (if the processing is based on consent)."
    },
    "securityTitle": "Securing your data",
    "securityDescription": "We take the protection of your personal data seriously and have implemented appropriate technical and organizational measures to secure your data against loss, misuse, and unauthorized access:",
    "security": {
      "ssl": "All data transmitted through our platforms is encrypted with SSL/TLS.",
      "encryption": "Data stored via Google Firebase is encrypted with AES-256.",
      "authorizedPersonnel": "Only authorized personnel have access to personal data, secured with two-factor authentication (2FA)."
    },
    "contactTitle": "Contact",
    "contactDetails": "If you have any questions about this privacy policy or wish to exercise your rights, you can contact us at "
  },
  "Terms-and-conditions": "Terms and Conditions",
  "terms": {
    "lastUpdated": "Last updated: December 2024",
    "introduction": "Welcome to summie®! These Terms and Conditions (\"Terms\") govern your use of the summie app (\"the App\") and our website, www.summie.co (\"the Website\"), operated by summie B.V. (\"we\", \"our\", \"us\"). By using our services, you agree to comply with these Terms. Please read them carefully.",
    "section1": {
      "title": "1. Use of the App",
      "description": "The App is designed to help users organize and manage receipts, invoices, and financial transactions. You must use the App in accordance with applicable laws and regulations."
    },
    "section2": {
      "title": "2. Account registration",
      "description": "To use certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your login details and ensuring the accuracy of the information provided."
    },
    "section3": {
      "title": "3. Subscription and payments",
      "description": "Users are required to commit to a subscription for the agreed term. Subscriptions renew automatically unless canceled before the renewal date. Users can cancel their subscription at any time through their account settings. Payments will be processed automatically via the provided payment method."
    },
    "section4": {
      "title": "4. User content",
      "description": "You are responsible for any content you upload to the App, including receipts and invoices. You agree not to upload illegal, harmful, or offensive content."
    },
    "section5": {
      "title": "5. Privacy",
      "description": "We respect your privacy. Please refer to our"
    },
    "section6": {
      "title": "6. Termination",
      "description": "We reserve the right to terminate your access to the App if you violate these Terms. Users may terminate their subscription at any time without providing a reason. Upon termination, access to the app will be revoked, and any data entered by the user may be deleted, subject to data retention policies."
    },
    "section7": {
      "title": "7. Liability and third-party claims",
      "description": "summie is not liable for any damages resulting from your use of the App, except where required by law. summie is indemnified from any claims by third parties arising from the user's use of the app, including but not limited to inaccuracies in user-entered data or misinterpretation of the app's functionalities."
    },
    "section8": {
      "title": "8. Governing law",
      "description": "These Terms are governed by the laws of the Netherlands. Any disputes arising from these terms and conditions will be submitted to the competent court in Amsterdam, The Netherlands."
    },
    "section9": {
      "title": "9. Changes to Terms and Conditions",
      "description": "summie B.V. reserves the right to amend these Terms and Conditions. In the event of significant changes, we will notify users via email, the app, or our website. By continuing to use our services after the effective date of the changes, you agree to the updated Terms and Conditions."
    },
    "disclaimer": {
      "title": "Disclaimer: responsibility for VAT filings",
      "description": "summie is an informative application designed to assist users in organizing and managing their administrative tasks, such as tracking receipts and transactions. While summie provides tools to facilitate the preparation of VAT filings, it does not assume responsibility for submitting VAT returns on behalf of users."
    },
    "userresponsibility": {
      "title": "User responsibilities:",
        "dataverification": "Data verification: Users are responsible for reviewing all data entered into summie to ensure its accuracy and completeness before submitting any information to tax authorities or other entities.",
        "accuracyoffilings": "Accuracy of filings: summie cannot guarantee the accuracy of VAT filings. The effectiveness of the app's tools depends on the correctness of the data provided by users."
    },
    "limitation": {
      "title": "Limitation of liability:",
      "description": "summie serves as a tool to aid in administrative tasks and cannot be held liable for any errors, inaccuracies, or omissions in the data provided by users. The export function is intended to assist users in preparing their VAT filings and should be used as a reference. Users are encouraged to consult with a qualified accountant or tax advisor to ensure compliance with all applicable tax laws and regulations."
    },
    "contactTitle": "Contact",
    "contactDescription": "If you have questions about these Terms, please contact us at"
  }
}
