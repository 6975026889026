import React from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../PageWrapper";

const StartingBusinessWithBrother = () => {
    return (
        <PageWrapper titleKey="Starting a business with my brother">
            <Helmet>
                <title>Starting a Business with My Brother | summie Blogs</title>
                <meta
                    name="description"
                    content="Learn how collaboration and balance helped us build a thriving business as siblings. Tips for working with family included."
                />
                <meta
                    name="keywords"
                    content="family business, collaboration, entrepreneurship, summie, sibling teamwork"
                />
                <meta name="author" content="Nathalie Bais" />
            </Helmet>
            <div className="content-section">
                <p>
                    Starting a business with my brother has been one of the most rewarding decisions we have made. It didn't take long for us to realize how complementary our skills were, making us a powerful team. I'm more focused on the business side of things, while he is a tech enthusiast with a structured work ethic who brings our ideas to life.
                </p>
                <h2>Finding our balance</h2>
                <p>
                    Working with family can be tricky, but we have discovered some strategies to ensure our personal relationship stays healthy while our business thrives:
                </p>
                <ul>
                    <li>Separate communication channels: We have a dedicated chat for work topics, so personal conversations don't get mixed up with business discussions.</li>
                    <li>Dedicated work time: We only discuss work during agreed-upon times, keeping work from creeping into family moments.</li>
                    <li>Open brainstorm sessions: We schedule regular brainstorms where we both speak freely and listen without interrupting.</li>
                    <li>Goal alignment: We write down our business goals and make sure they align with our personal values and preferences.</li>
                </ul>
                <h2>Tips for working with family</h2>
                <ul>
                    <li>Define clear roles: Play to each other's strengths and clarify who handles what.</li>
                    <li>Communicate openly: Be honest about expectations and concerns.</li>
                    <li>Respect boundaries: Separate personal and professional time.</li>
                    <li>Establish conflict resolution strategies: Agree on how to handle disagreements constructively.</li>
                    <li>Celebrate successes together: Take time to acknowledge milestones and wins as a team.</li>
                    <li>Encourage personal growth: Support each other's learning and development within the business.</li>
                </ul>
                <p>
                    Building something meaningful together has strengthened both our bond and our business success. If you are thinking about working with a family member, creating clear structures and mutual respect can make all the difference.
                </p>
                <h2>summie: The result of our collaboration</h2>
                <p>
                    Our business, summie, is the perfect example of how our collaboration turned into something special. The first few weeks were hilarious—somehow, we both imagined the same colors, design elements, and features without even discussing them. It was as if our shared vision had already taken shape. Working together on summie has not only made our bond stronger but also shown us that great ideas can flourish when you find the right balance with your business partner.
                </p>
            </div>
        </PageWrapper>
    );
};

export default StartingBusinessWithBrother;
