import React from 'react';
import CookieConsent from 'react-cookie-consent';
import "./CookieConsent.css";

const CookieConsentPopup = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      declineButtonText="Decline"
      enableDeclineButton
      cookieName="site_cookie_consent"
      containerClasses="cookie-container" // Link to the container class in the CSS
      style={{
        background: "#1A1A1A",
        fontSize: "14px",
        textAlign: "center",
        fontFamily: "'Inter', sans-serif",
      }}
      buttonStyle={{
        color: "#FFFFFF",
        fontSize: "14px",
        background: "#6C53B7",
        borderRadius: "5px",
        padding: "10px 20px",
        border: "none",
        margin: "5px",
        cursor: "pointer",
      }}
      declineButtonStyle={{
        color: "#FFFFFF",
        background: "#555555",
        fontSize: "14px",
        borderRadius: "5px",
        padding: "10px 20px",
        border: "none",
        margin: "5px",
        cursor: "pointer",
      }}
      onAccept={() => {
        console.log("Cookies Accepted");
        // Enable GTM tracking or other cookies here
      }}
      onDecline={() => {
        console.log("Cookies Declined");
        // Disable GTM tracking or other cookies here
      }}
    >
      This website uses cookies to enhance the user experience. You can manage your preferences.
    </CookieConsent>
  );
};

export default CookieConsentPopup;