import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PageWrapper from "../PageWrapper";

const BlogsOverview = () => {
    const blogs = [
        {
            title: "Empowering Gen-Z and Millennials",
            route: "/blog/empowering-gen-z",
            excerpt: "Learn why financial literacy is crucial for young entrepreneurs.",
            date: "January, 2025",
        },
        {
            title: "Starting a business with my brother",
            route: "/blog/starting-business-with-brother",
            excerpt: "Discover how we found the perfect balance and made our business thrive.",
            date: "January, 2025",
        },
        {
            title: "Why you can do your own VAT with minimum effort",
            route: "/blog/why-diy-vat",
            excerpt: "Explore how AI-powered tools like summie make VAT simple and stress-free.",
            date: "January, 2025",
        },
    ];

    return (
        <PageWrapper titleKey="Our blogs">
            <Helmet>
                <title>Blogs overview | summie</title>
                <meta
                    name="description"
                    content="Discover all blogs by summie, covering financial literacy, entrepreneurship, and more."
                />
                <meta name="keywords" content="summie, Blogs, Financial Literacy, VAT, Entrepreneurship" />
                <meta name="author" content="summie" />
            </Helmet>
            <div className="content-section">
                <ul className="blogs-list">
                    {blogs.map((blog, index) => (
                        <li key={index} className="blog-item">
                            <Link to={blog.route} className="blog-link">
                                <h2>{blog.title}</h2>
                                <p>{blog.excerpt}</p>
                                <p className="blog-date">{blog.date}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </PageWrapper>
    );
};

export default BlogsOverview;
