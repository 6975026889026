import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n'; // Import the i18n configuration
import TagManager from 'react-gtm-module'; // Import the GTM module

// Initialize GTM
const tagManagerArgs = {
  gtmId: 'GTM-KHSQ2N6T',
};
TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
} else {
  console.error('Root element not found');
}