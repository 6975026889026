import React, { useState } from "react";
import { Link } from 'react-router-dom'; 
import { useTranslation } from "react-i18next";
import "/src/desktop/screens/Lp/style.css";

export const Lp = () => {
  const { t, i18n } = useTranslation();

  // Toggle between English and Dutch
  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "nl" : "en";
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="LP">
      <div className="div">
        <div className="overlap">
          <img className="gradient" alt="Gradient" src="/img/desktop/gradient.svg" />

          <div className="background">
            <div className="container">
              <div className="container-2">
                <div className="overlay-shadow">
                  <div className="div-wrapper">
                    <div className="text-wrapper">{t("result")}</div>
                  </div>

                  <img
                    className="mask-group"
                    alt="Mask group"
                    src="/img/desktop/mask-group-3.svg"
                  />
                </div>

                <div className="container-3">
                  <p className="p">{t("combined_skills")}</p>
                  <p className="text-wrapper-2">{t("dream")}</p>
                </div>
              </div>
            </div>

            <div className="group-wrapper">
              <div className="group">
                <div className="overlap-group">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-2">
                      <div className="text-wrapper-3">Modern Finance,</div>

                      <img
                        className="made-simple"
                        alt="Made simple"
                        src="/img/desktop/made-simple.svg"
                      />
                    </div>
                  </div>

                  <img className="img" alt="Group" src="/img/desktop/group.png" />
                  <img className="group-2" alt="Group" src="/img/desktop/group-1.png" />
                  <img className="group-3" alt="Group" src="/img/desktop/group-2.png" />
                  <img className="group-4" alt="Group" src="/img/desktop/group-3.png" />
                  <img className="group-5" alt="Group" src="/img/desktop/group-4.png" />
                  <img className="group-6" alt="Group" src="/img/desktop/group-5.png" />
                  <img className="group-7" alt="Group" src="/img/desktop/group-6.png" />
                  <img className="group-8" alt="Group" src="/img/desktop/group-7.png" />
                  <img className="group-9" alt="Group" src="/img/desktop/group-8.png" />

                  <img
                    className="group-10"
                    alt="Group"
                    src="/img/desktop/group-9.png"
                  />

                  <img
                    className="group-11"
                    alt="Group"
                    src="/img/desktop/group-10.png"
                  />

                  <img
                    className="group-12"
                    alt="Group"
                    src="/img/desktop/group-11.png"
                  />

                  <img
                    className="group-13"
                    alt="Group"
                    src="/img/desktop/group-12.png"
                  />

                  <img
                    className="group-14"
                    alt="Group"
                    src="/img/desktop/group-13.png"
                  />

                  <img
                    className="group-15"
                    alt="Group"
                    src="/img/desktop/group-14.png"
                  />

                  <img
                    className="clip-path-group"
                    alt="Clip path group"
                    src="/img/desktop/clip-path-group.png"
                  />

                  <img
                    className="group-16"
                    alt="Group"
                    src="/img/desktop/group-1073714129.png"
                  />

                  <div className="group-17" />
                  <div className="group-18" />
                  <div className="group-19" />
                  <div className="text-wrapper-4">+25%</div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-4">
            <div className="icon">
              <img className="union" alt="Union" src="/img/desktop/union.svg" />
            </div>

            <div className="heading-wrapper">
              <div className="heading">
               <p className="upgrade-to-the-new">{t("upgrade_message")}</p>
              </div>
            </div>

            <div className="follow">
              <a
              href="https://apps.apple.com/app/summie/id6737628622"
              target="_blank"
              rel="noopener noreferrer"
              >
                <div className="border" />
                <img className="SVG" alt="Svg" src="/img/desktop/svg-2.svg" />
                <div className="text-wrapper-5">{t("Download")}</div>
              </a>
            </div>
          </div>

          <div className="follow-2">
            <a
              href="https://www.instagram.com/summie.app?igsh=dnAwdTg1cGhhM2g3"
              target="_blank"
              rel="noopener noreferrer"
            >
            <div className="content-wrapper">
              <img
                className="image"
                alt="Instagram Logo"
                src="/img/desktop/image-7.png"
              />
            <div className="text-container">
              <span className="white-text">{t("Follow us")}</span>{' '}
              <span className="light-purple-text">{t("on Instagram for updates")}</span>
            </div>
          </div>
          <div className="border-2" />
          </a>
          </div>

          <div className="follow-3">
            <a
            href="https://apps.apple.com/app/summie/id6737628622"
            target="_blank"
            rel="noopener noreferrer"
            >
              <div className="border" />
              <img className="SVG" alt="Svg" src="/img/desktop/svg-3.svg" />
              <div className="text-wrapper-6">{t("Download")}</div>
            </a>
          </div>
          <div className="text-wrapper-7">
          <a href="mailto:hello@summie.co">hello@summie.co</a>
          </div>
          <img className="logo" alt="Logo" src="/img/desktop/logo.svg" />
        </div>

        <div className="overlap-2">
          <img
            className="group-20"
            alt="Group"
            src="/img/desktop/group-1073714128.png"
          />

          <div className="rectangle" />
          <img className="blur" alt="Blur" src="/img/desktop/blur-1.svg" />
          <div className="follow-4">
            <a
            href="https://apps.apple.com/app/summie/id6737628622"
            target="_blank"
            rel="noopener noreferrer"
            >
              <div className="border" />
              <img className="SVG" alt="Svg" src="/img/desktop/svg.svg" />
              <div className="text-wrapper-5">{t("Download")}</div>
            </a>
          </div>

          <div className="iphone-pro">
            <div className="overlap-3">
              <img className="silver" alt="Silver" src={t("overview-desktop")} />
            </div>
          </div>

          <div className="container-wrapper">
            <div className="container-5">
              <div className="overlap-6">
                <div className="horizontal-divider" />
                <div className="overlap-wrapper">
                  <div className="overlap-7">
                    <div className="div-2">
                      <div className="container-6">
                        <div className="text-wrapper-28">{t("Export your files")}</div>
                      </div>

                      <div className="container-7">
                        <div className="text-wrapper-29">{t("for easy sharing")}</div>
                      </div>

                      <div className="vertical-divider" />
                    </div>

                    <img
                      className="frame-26"
                      alt="Frame"
                      src="/img/desktop/frame-22.svg"
                    />
                  </div>
                </div>

                <div className="container-8">
                  <div className="container-9">
                    <div className="text-wrapper-30">{t("Data security focus")}</div>
                  </div>

                  <div className="container-10">
                    <div className="text-wrapper-31">
                      {t("your privacy, fully protected")}
                    </div>
                  </div>

                  <img
                    className="frame-27"
                    alt="Frame"
                    src="/img/desktop/frame-23.svg"
                  />
                </div>
              </div>

              <div className="div-2">
                <div className="overlap-7">
                  <div className="div-2">
                    <div className="receipt-capture-wrapper">
                      <div className="receipt-capture">
                        {t("Receipt capture")}
                      </div>
                    </div>

                    <div className="container-11">
                      <p className="text-wrapper-32">{t("all in one secure place")}</p>
                    </div>

                    <div className="vertical-divider" />
                  </div>

                  <img
                    className="frame-28"
                    alt="Frame"
                    src="/img/desktop/frame-15.svg"
                  />
                </div>
              </div>

              <div className="container-12">
                <div className="overlap-7">
                  <img
                    className="frame-29"
                    alt="Frame"
                    src="/img/desktop/frame-16.svg"
                  />

                  <div className="div-2">
                    <div className="organize-track-wrapper">
                      <div className="text-wrapper-28">
                        {t("Organize")}
                      </div>
                    </div>

                    <div className="container-13">
                      <div className="text-wrapper-29">
                        {t("easy expense management")}
                      </div>
                    </div>

                    <div className="vertical-divider" />
                  </div>
                </div>
              </div>

              <div className="container-14">
                <div className="container-15">
                  <div className="text-wrapper-28">{t("Intuitive design")}</div>
                </div>

                <div className="container-16">
                  <div className="text-wrapper-29">{t("and simple to navigate")}</div>
                </div>

                <img className="frame-30" alt="Frame" src="/img/desktop/frame-21.svg" />
              </div>
            </div>
          </div>
        </div>

        <div className="overlap-8">
          <img
            className="frame-31"
            alt="Frame"
            src="/img/desktop/frame-1073714006.png"
          />

          <img className="blur-2" alt="Blur" src="/img/desktop/blur.svg" />

            <div className="margin">
              <div className="container-17">
                <div className="container-18">
                  <div className="heading-margin">
                    <p className="save-time-and-money">
                      {t("Save time and money")}
                      <br />
                      {t("with summie.")}
                      <sup>®</sup>
                    </p>
                  </div>

                  <div className="margin-2">
                    <div className="container-19">
                      <p className="text-wrapper-33">{t("We simplify")}</p>
                    </div>
                  </div>
                </div>

                <div className="follow">
                  <a
                    href="https://apps.apple.com/app/summie/id6737628622"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="border" />
                    <img className="SVG" alt="Svg" src="/img/desktop/svg-1.svg" />
                    <div className="text-wrapper-5">{t("Download")}</div>
                  </a>
                </div>
              </div>
            </div>

            <video
              autoPlay
              muted
              playsInline
              className="iphone-pro-mockup"
              alt="Iphone pro mockup"
            >
              <source src="/img/desktop/LandingPageAnimation.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

        <div className="container-20">
          <div className="frame-32">
            <div className="overlap-9">
              <div className="background-2">
                <div className="heading-margin-2">
                  <div className="div-3">
                    <div className="text-wrapper-34">{t("Save time")}</div>
                  </div>
                </div>

                <div className="container-21">
                  <p className="text-wrapper-35">{t("No more paper")}</p>
                </div>

                <img
                  className="mask-group-2"
                  alt="Mask group"
                  src="/img/desktop/mask-group.svg"
                />
              </div>

              <div className="archive-3">
                <img
                  className="frame-33"
                  alt="Frame"
                  src={t("library-desktop")}
                />
              </div>
            </div>
          </div>

          <div className="frame-32">
            <div className="overlap-9">
              <div className="background-2">
                <div className="heading-margin-2">
                  <div className="div-3">
                    <div className="text-wrapper-34">{t("Save money")}</div>
                  </div>
                </div>

                <div className="container-21">
                  <p className="text-wrapper-35">{t("Manage your financial")}</p>
                </div>

                <img
                  className="mask-group-2"
                  alt="Mask group"
                  src="/img/desktop/mask-group-1.svg"
                />
              </div>

              <div className="archive-3">
                  <img
                    className="frame-49"
                    alt="Frame"
                    src={t("widget-desktop")}
                  />
              </div>
            </div>
          </div>
        </div>

        <div className="container-22">
          <div className="overlay-shadow">
            <div className="div-wrapper">
              <div className="text-wrapper">{t("Our motto")}</div>
            </div>

            <img
              className="mask-group-3"
              alt="Mask group"
              src="/img/desktop/mask-group-2.svg"
            />
          </div>

          <div className="heading-2">
            <div className="text-wrapper-46">{t("Why summie")}</div>
          </div>

          <p className="in-today-s-fast">
            {t("In today’s fast-paced world")} <br />
            {t("We are here to")}
          </p>
        </div>

        <div className="container-23">
          <div className="heading-3">
            <p className="text-wrapper-47">{t("The app that works for you")}</p>
          </div>

          <div className="container-24">
            <p className="text-wrapper-48">{t("With summie")}</p>
          </div>

          <div className="overlay-shadow-2">
            <div className="div-wrapper">
              <div className="text-wrapper">{t("Features")}</div>
            </div>

            <img
              className="mask-group-4"
              alt="Mask group"
              src="/img/desktop/mask-group-4.svg"
            />
          </div>
        </div>

        <div className="section">
          <div className="container-25">
            <div className="container-26">
              <div className="container-27">
                <div className="container-28">
                  <div className="div-3">
                    <div className="div-3">
                      <p className="text-wrapper-49">
                        {t("summie started with a simple idea")}
                        {t("of the paper clutter")}
                      </p>
                    </div>

                    <div className="container-29">
                      <p className="text-wrapper-50">
                        {t("summie started with a simple idea")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="frame-61">
          <div className="container-30">
            <div className="list-wrapper">
              <div className="list">
                <div className="div-wrapper-2">
                  <div className="text-wrapper-51">
                    <Link to="/privacy-policy" className="privacy-policy-link">
                      {t("Privacy Policy")}
                    </Link>
                  </div>
                </div>

                <div className="div-wrapper-2">
                  <div className="text-wrapper-51">
                    <Link to="/terms-and-conditions" className="terms-and-conditions-link">
                      {t("Terms and Conditions")}
                    </Link>
                  </div>
                </div>

                <div className="div-wrapper-2">
                  <div className="text-wrapper-51">
                    <Link to="/blogs" className="blogs-link">
                      {t("Blogs")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="div-wrapper-2">
              <p className="text-wrapper-51">
                {t("summie B.V. All rights reserved.")}
              </p>
            </div>
          </div>
        </div>

        <div className="follow-5">
        <a
        href="https://apps.apple.com/app/summie/id6737628622"
        target="_blank"
        rel="noopener noreferrer"
        >
            <div className="border" />
            <img className="SVG" alt="Svg" src="/img/desktop/svg-3.svg" />
            <div className="text-wrapper-6">{t("Download")}</div>
        </a>
        </div>

        <div className="language-toggle">
          <button onClick={toggleLanguage}>
            {i18n.language === "en" ? "nl" : "en"}
          </button>
        </div>

        <a href="/">
          <img
            className="group-21"
            alt="Group"
            src="/img/desktop/group-1073714096.svg"
          />
        </a>
      </div>
    </div>
  );
};
