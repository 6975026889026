import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DesktopApp from './desktop/index';
import MobileApp from './mobile/App';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import ScrollToTop from './ScrollToTop';
import CookieConsentPopup from './CookieConsent';
import BlogsOverview from './blogs/BlogsOverview';
import EmpoweringGenZ from './blogs/EmpoweringGenZ';
import StartingBusinessWithBrother from './blogs/StartingBusinessWithBrother';
import WhyDIYVAT from './blogs/WhyDIYVAT';
import LinkedInInsightTag from './LinkedInInsightTag';

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      {/* LinkedIn Insight Tag */}
      <LinkedInInsightTag />

      {/* Scroll Restoration */}
      <ScrollToTop />

      {/* Cookie Consent */}
      <CookieConsentPopup isMobile={isMobile} />

      {/* App Routes */}
      <Routes>
        {/* Static Pages */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

        {/* Blog Pages */}
        <Route path="/blogs" element={<BlogsOverview />} />
        <Route path="/blog/empowering-gen-z" element={<EmpoweringGenZ />} />
        <Route path="/blog/starting-business-with-brother" element={<StartingBusinessWithBrother />} />
        <Route path="/blog/why-diy-vat" element={<WhyDIYVAT />} />

        {/* Main App */}
        <Route path="/*" element={isMobile ? <MobileApp /> : <DesktopApp />} />
      </Routes>
    </Router>
  );
};

export default App;