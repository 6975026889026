{
  "result": "Resultaat",
  "combined_skills": "Samen hebben we onze vaardigheden in business en technologie gecombineerd om summie te creëren",
  "dream": "- de app die zowel tijd als geld bespaart. We dromen ervan om financiële kennis toegankelijk te maken voor iedereen.",
  "upgrade_message": "Upgrade naar de nieuwe manier van uitgavenbeheer",
  "Save time and money": "Bespaar tijd en geld",
  "with summie.": "met summie",
  "We simplify": "Wij vereenvoudigen je financiën door alles digitaal en overzichtelijk te houden, zodat je tijd en geld bespaart.",
  "Download": "Download",
  "Our motto": "Ons motto",
  "Why summie": "Waarom summie",
  "In today’s fast-paced world": "In de snelle wereld van vandaag zou het beheren van je financiën je niet moeten vertragen.",
  "We are here to": "Wij zijn er om je financiële administratie te vereenvoudigen, zodat je tijd en geld bespaart met een intuïtieve, digitale aanpak.",
  "Save time": "Bespaar tijd",
  "Save money": "Bespaar geld",
  "No more paper": "Geen papieren rommel of handmatig bijhouden meer. summie houdt alles digitaal, overzichtelijk en is ontworpen om intuïtief te zijn.",
  "Manage your financial": "Beheer je financiële administratie efficiënt. summie biedt haar diensten aan tegen een scherpe prijs, zodat je overal geld bespaart.",
  "Features": "Functies",
  "The app that works for you": "De app die voor jou werkt",
  "With summie": "Met summie heb je al je bonnen en facturen op één plek. Voeg moeiteloos je bon toe en koppel deze direct aan je transactie. summie maakt het eenvoudig voor elke ondernemer, freelancer of particulier die hun financiële administratie wil vereenvoudigen.",
  "Receipt capture": "Bonnen vastleggen & opslaan",
  "all in one secure place": "alles op één veilige plek",
  "Organize": "Organiseer & volg",
  "easy expense management": "eenvoudig uitgavenbeheer",
  "Intuitive design": "Intuïtief ontwerp",
  "and simple to navigate": "en eenvoudig te navigeren",
  "Export your files": "Exporteer je bestanden",
  "for easy sharing": "voor eenvoudig delen",
  "Data security focus": "Focus op gegevensbeveiliging",
  "your privacy, fully protected": "je privacy, volledig beschermd",
  "summie started with a simple idea": "summie begon met een simpel idee: wat als we het beheren van bonnen eenvoudig konden maken? Als ondernemers waren we het zat ",
  "of the paper clutter": "om te worstelen met papieren rommel en inefficiënte tools. We wisten dat er iets beters moest komen, niet alleen voor onszelf, maar voor elke professional of particulier die hun tijd waardeert.",
  "summie B.V. All rights reserved.": "summie B.V. Alle rechten voorbehouden.",
  "Follow us": "Volg ons",
  "on Instagram for updates": "op Instagram voor updates",
  "Privacy Policy": "Privacybeleid",
  "Terms and Conditions": "Algemene voorwaarden",
  "Blogs": "Blogs",
  "overview-mobile": "/img/mobile/overview-nl.png",
  "overview-desktop": "/img/desktop/overview-nl.png",
  "library-mobile": "/img/mobile/library-nl.png",
  "library-desktop": "/img/desktop/library-nl.png",
  "widget-mobile": "/img/mobile/widget-nl.png",
  "widget-desktop": "/img/desktop/widget-nl.png",
  "Privacy-policy": "Privacybeleid",
  "privacy": {
    "lastUpdated": "Laatst bijgewerkt: januari 2025",
    "introduction": "summie B.V. (hierna 'summie', 'wij', 'onze') respecteert jouw privacy en zorgt ervoor dat jouw persoonsgegevens veilig worden verwerkt. Dit privacybeleid legt uit hoe wij jouw persoonsgegevens verzamelen, gebruiken, opslaan en beschermen wanneer je gebruik maakt van onze diensten, zowel via onze website summie.co als via onze app. Dit beleid is opgesteld conform de Algemene Verordening Gegevensbescherming (AVG) en andere relevante wetgeving.",
    "whatDataTitle": "Welke gegevens verzamelen wij?",
    "whatDataDescription": "Wij verzamelen verschillende soorten persoonsgegevens van gebruikers die onze website en/of app gebruiken:",
    "data": {
      "registration": {
        "title": "Registratie en authenticatiegegevens:",
        "name": "Naam",
        "email": "E-mailadres",
        "userID": "Uniek gebruikers-ID",
        "password": "Versleuteld wachtwoord (bij handmatige registratie)"
      },
      "payment": {
        "title": "Betalingsinformatie:",
        "details": "Bankaccountgegevens, zoals IBAN en transactie-informatie, verzameld via onze betalingsprovider GoCardless."
      },
      "technical": {
        "title": "Technische gegevens:",
        "details": "Gegevens over je gebruik van onze app, zoals apparaatinformatie, locatiegegevens (indien toegestaan) en andere loggegevens via cookies en vergelijkbare technologieën."
      },
      "analytics": {
        "title": "Data analytics:",
        "details": "Geanonimiseerde gebruikersinteracties, waaronder scrolls en kliks."
      },
      "advertising": {
        "title": "Advertentie- en conversietrackinggegevens:",
        "details": "Gegevens verzameld via de Facebook SDK, waaronder conversiegegevens."
      }
    },
    "whyDataTitle": "Waarom verzamelen wij deze gegevens?",
    "whyDataDescription": "Wij gebruiken jouw gegevens voor de volgende doeleinden:",
    "whyData": {
      "createAccount": "Om een gebruikersaccount aan te maken en toegang te bieden tot onze diensten.",
      "processSubscriptions": "Voor het verwerken van abonnementen via RevenueCat.",
      "transactions": "Voor het verwerken van bank account data en het afhandelen van transacties via GoCardless.",
      "userExperience": "Om de gebruikservaring te verbeteren door het analyseren van gebruikspatronen en voorkeuren.",
      "advertisingEffects": "Om de effectiviteit van advertenties op platforms zoals Instagram te meten en optimaliseren.",
      "legalObligations": "Om te voldoen aan wettelijke verplichtingen en om de veiligheid van onze systemen te waarborgen."
    },
    "howDataTitle": "Hoe verzamelen wij gegevens?",
    "howDataDescription": "Wij verzamelen gegevens op verschillende manieren, afhankelijk van je interactie met onze diensten:",
    "howData": {
      "createAccount": "Wanneer je een account aanmaakt of inlogt via Apple of Google.",
      "connectBank": "Wanneer je jouw bank koppelt via GoCardless.",
      "technicalData": "Wij verzamelen automatisch technische gegevens via Google Firebase over je gebruik van de app.",
      "analytics": "Tijdens het gebruik van de app worden geanonimiseerde gebruikersinteracties opgeslagen met Microsoft Clarity.",
      "advertising": "Wij verzamelen en gebruiken de Identifier for Advertisers (IDFA) van uw apparaat om de effectiviteit van advertenties te volgen en te meten voor campagnes die worden uitgevoerd op platforms zoals Instagram. Deze gegevens worden gedeeld met Facebook voor attributie- en conversietrackingsdoeleinden."
    },
    "shareDataTitle": "Deelt summie mijn gegevens met derden?",
    "shareDataDescription": "Ja, wij delen jouw persoonsgegevens met derden, maar alleen wanneer dit noodzakelijk is voor de levering van onze diensten:",
    "shareData": {
      "gocardless": "GoCardless: Voor het verwerken van transacties en bankgegevens.",
      "firebase": "Google Firebase: Voor hosting, opslag en databasebeheer van je gegevens.",
      "appleGoogle": "Apple en Google (OAuth): Voor authenticatie via 'Login met Apple' en 'Login met Google'.",
      "revenuecat": "Apple en RevenueCat: Voor abonnement en betalingen.",
      "microsoftClarity": "Microsoft Clarity: Voor appgebruik en onderzoeken van problemen in de app.",
      "facebook": "Facebook: Voor het bijhouden van de effectiviteit van advertenties en het meten van conversies op platforms zoals Instagram.",
      "devTeams": "Externe ontwikkelteams: Onze ontwikkelteams kunnen toegang hebben tot gegevens indien nodig voor onderhoud en verbetering van de app."
    },
    "yourRightsTitle": "Jouw rechten",
    "yourRightsDescription": "Als gebruiker van onze diensten heb je verschillende rechten met betrekking tot jouw persoonsgegevens:",
    "yourRights": {
      "access": "Je hebt het recht om in te zien welke gegevens wij van jou verwerken.",
      "correct": "Je kunt ons verzoeken om onjuiste of onvolledige gegevens te corrigeren.",
      "delete": "Je kunt verzoeken om je persoonsgegevens te laten verwijderen (tenzij wij wettelijk verplicht zijn om de gegevens langer te bewaren).",
      "restrict": "Je kunt verzoeken om de verwerking van je persoonsgegevens tijdelijk te beperken.",
      "withdraw": "Je kunt jouw toestemming voor het verwerken van gegevens intrekken (indien de verwerking op toestemming is gebaseerd)."
    },
    "securityTitle": "Beveiliging van jouw gegevens",
    "securityDescription": "Wij nemen de bescherming van jouw persoonsgegevens serieus en hebben passende technische en organisatorische maatregelen getroffen om je gegevens te beveiligen tegen verlies, misbruik en ongeautoriseerde toegang:",
    "security": {
      "ssl": "Alle gegevens die via onze platformen worden verzonden, worden versleuteld met SSL/TLS.",
      "encryption": "Gegevens die worden opgeslagen via Google Firebase zijn versleuteld met AES-256.",
      "authorizedPersonnel": "Alleen geautoriseerde medewerkers hebben toegang tot persoonsgegevens, en deze toegang wordt beveiligd met tweefactorauthenticatie (2FA)."
    },
    "contactTitle": "Contact",
    "contactDetails": "Als je vragen hebt over dit privacybeleid of je rechten wilt uitoefenen, kun je contact met ons opnemen via "
  },
  "Terms-and-conditions": "Algemene voorwaarden",
  "terms": {
    "lastUpdated": "Laatste update: december 2024",
    "introduction": "Welkom bij summie®! Deze Algemene Voorwaarden (\"Voorwaarden\") regelen jouw gebruik van de summie-app (\"de App\") en onze website, www.summie.co (\"de Website\"), beheerd door summie B.V. (\"wij\", \"ons\", \"onze\"). Door gebruik te maken van onze diensten, ga je akkoord met deze Voorwaarden. Lees ze zorgvuldig door.",
    "section1": {
      "title": "1. Gebruik van de App",
      "description": "De App is ontworpen om gebruikers te helpen bij het organiseren en beheren van bonnetjes, facturen en financiële transacties. Je moet de App gebruiken in overeenstemming met toepasselijke wet- en regelgeving."
    },
    "section2": {
      "title": "2. Account registratie",
      "description": "Om bepaalde functies te gebruiken, moet je mogelijk een account aanmaken. Je bent verantwoordelijk voor het vertrouwelijk houden van je inloggegevens en het verstrekken van juiste informatie."
    },
    "section3": {
      "title": "3. Abonnement en betalingen",
      "description": "Toegang tot premiumfuncties van de App kan een abonnement vereisen. Door je te abonneren ga je akkoord met de prijzen en betalingsvoorwaarden die op het moment van abonnement worden vermeld. Abonnementen worden automatisch verlengd, tenzij ze vóór de verlengingsdatum worden geannuleerd. Gebruikers kunnen hun abonnement op elk moment annuleren via hun accountinstellingen. Betalingen worden automatisch verwerkt via de opgegeven betaalmethode."
    },
    "section4": {
      "title": "4. Gebruikersinhoud",
      "description": "Je bent verantwoordelijk voor alle inhoud die je uploadt naar de App, inclusief bonnetjes en facturen. Je stemt ermee in geen illegale, schadelijke of aanstootgevende inhoud te uploaden."
    },
    "section5": {
      "title": "5. Privacy",
      "description": "Wij respecteren jouw privacy. Raadpleeg ons"
    },
    "section6": {
      "title": "6. Beëindiging",
      "description": "Wij behouden ons het recht voor om jouw toegang tot de App te beëindigen als je deze Voorwaarden schendt. Gebruikers kunnen hun abonnement op elk moment beëindigen zonder opgave van reden. Na beëindiging wordt de toegang tot de App ingetrokken en kunnen ingevoerde gegevens worden verwijderd, met inachtneming van ons beleid voor gegevensbewaring."
    },
    "section7": {
      "title": "7. Aansprakelijkheid en claims van derden",
      "description": "summie is niet aansprakelijk voor enige schade die voortvloeit uit jouw gebruik van de App, behalve waar wettelijk vereist. summie is gevrijwaard van claims door derden die voortvloeien uit het gebruik van de App door de gebruiker, inclusief maar niet beperkt tot onjuistheden in ingevoerde gegevens of misinterpretatie van de functies van de App."
    },
    "section8": {
      "title": "8. Toepasselijk recht",
      "description": "Deze Voorwaarden worden beheerst door de wetten van Nederland. Eventuele geschillen die voortvloeien uit deze Voorwaarden zullen worden voorgelegd aan de bevoegde rechtbank in Amsterdam, Nederland."
    },
    "section9": {
      "title": "9. Wijzigingen in de Algemene Voorwaarden",
      "description": "summie B.V. behoudt zich het recht voor om deze Voorwaarden te wijzigen. Bij significante wijzigingen zullen wij gebruikers op de hoogte stellen via e-mail, de App of onze website. Door onze diensten te blijven gebruiken na de ingangsdatum van de wijzigingen, stem je in met de bijgewerkte Voorwaarden."
    },
    "disclaimer": {
      "title": "Disclaimer: Verantwoordelijkheid voor btw-aangiften",
      "description": "summie is een informatieve applicatie die is ontworpen om gebruikers te helpen bij het organiseren en beheren van hun administratieve taken, zoals het bijhouden van bonnetjes en transacties. Hoewel summie hulpmiddelen biedt om de voorbereiding van btw-aangiften te vergemakkelijken, neemt het geen verantwoordelijkheid voor het indienen van btw-aangiften namens gebruikers."
    },
    "userresponsibility": {
      "title": "Verantwoordelijkheden van de gebruiker:",
        "dataverification": "Gegevenscontrole: Gebruikers zijn verantwoordelijk voor het controleren van alle gegevens die in summie zijn ingevoerd om de juistheid en volledigheid ervan te garanderen voordat informatie wordt ingediend bij belastingautoriteiten of andere instanties.",
        "accuracyoffilings": "Nauwkeurigheid van aangiften: summie kan geen garantie bieden voor de nauwkeurigheid van btw-aangiften. De effectiviteit van de hulpmiddelen in de app is afhankelijk van de juistheid van de door gebruikers verstrekte gegevens."
    },
    "limitation": {
      "title": "Beperking van aansprakelijkheid:",
      "description": "summie fungeert als een hulpmiddel om administratieve taken te ondersteunen en kan niet aansprakelijk worden gesteld voor fouten, onnauwkeurigheden of weglatingen in de door gebruikers verstrekte gegevens. De exportfunctie is bedoeld om gebruikers te helpen bij het voorbereiden van hun btw-aangiften en dient als referentie. Gebruikers worden aangemoedigd om een gekwalificeerde accountant of belastingadviseur te raadplegen om te zorgen voor naleving van alle toepasselijke belastingwetten en -voorschriften."
    },
    "contactTitle": "Contact",
    "contactDescription": "Als je vragen hebt over deze Voorwaarden, neem dan contact met ons op via"
  }
}
